import "./projects.scss";
import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { PageTitle } from "../../components/title/title";
import Helmet from "react-helmet";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import { AmazeeIOButton } from "../../components/button/button";
import Linked from "../../utils/linkedItem";
import RichTextRender from "../../utils/rich-text";
import usePreviewData from "../../utils/usePreviewData";
import {
  TypeProjectPageBlock,
  TypeProjectPageData,
  TypeProjectPageQueryResult,
  TypeProjectPageSellingPoint,
  TypeProjectPageService,
  TypeProjectPageTechnologyLogo,
} from "../../utils/queries/projects";
import classNames from "classnames";

const ProjectsPageBlock: React.FC<{
  block: TypeProjectPageBlock;
  index: number;
}> = ({ block, index }) => {
  const { image, image_placement, text } = block;
  const imageEl = (
    <div
      className={classNames(
        "bg-cover bg-center w-full bg-no-repeat bg-contain",
        {}
      )}
      style={{
        height: index === 0 ? 420 : 320,
        backgroundImage: `url('${image.url}')`,
      }}
    />
  );
  const textEl = <div className="item-block-text">{RichTextRender(text)}</div>;

  return (
    <div className={classNames("row item-block hero-area", {})}>
      <div className={classNames("col-12", {})}>
        <div
          className={classNames("row", {
            "items-center": index > 0,
            "mt-10": index === 0,
          })}
        >
          {/* <div className="block md:hidden col-12 col-md-6">
            <div className="px-4">{imageEl}</div>
          </div>
          <div className="block md:hidden col-12 col-md-6">{textEl}</div> */}

          {/* Desktop: Display in intended order -- switch on mobile */}
          <div className="md:block col-12 col-md-6 display-first">
            {image_placement ? textEl : imageEl}
          </div>
          <div className="md:block col-12 col-md-6 display-second">
            {image_placement ? imageEl : textEl}
          </div>
        </div>
      </div>
    </div>
  );
};

// const ProjectsLogoSection: React.FC<{ title: string, logos: TypeProjectPageTechnologyLogo[] }> = ({title, logos}) => {
//     return <>
//         <h5 className="text-center">{RichTextRender(title)}</h5>
//         <div className="flex mt-10 flex-wrap items-center justify-center">
//             {logos.map((logo, index) => {
//                 return <img className="w-1/2 md:w-1/5 px-4" src={logo.logo.url} alt={logo.logo.alt}/>
//             })}
//         </div>
//     </>
// }

const ProjectServiceSection: React.FC<{
  sellingPoint: TypeProjectPageService;
}> = ({ sellingPoint }) => {
  return (
    <div className="col-12 col-md-6 mt-5 service">
      <div className="w-full flex items-center text-center flex-col">
        <div className="w-full" style={{ width: 100 }}>
          <img
            src={sellingPoint.image.url}
            alt={sellingPoint.image.alt}
            className="w-full"
          />
        </div>
        <h5 className="service-title">{sellingPoint.title3}</h5>
        {RichTextRender(sellingPoint.text)}
      </div>
    </div>
  );
};

const ProjectSellingPoint: React.FC<{
  service: TypeProjectPageSellingPoint;
}> = ({ service }) => {
  return (
    <div className="col-12 col-md-6 mt-5">
      <div className="block lg:hidden">
        <div className="w-full" style={{ paddingLeft: 20, paddingRight: 20 }}>
          <img
            src={service.image.url}
            alt={service.image.alt}
            className="w-full"
          />
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="w-full" style={{ paddingLeft: 80, paddingRight: 80 }}>
          <img
            src={service.image.url}
            alt={service.image.alt}
            className="w-full"
          />
        </div>
      </div>
      <h5 style={{ marginTop: 10 }}>{service.title1}</h5>
      {RichTextRender(service.text)}
      <Linked link_to={service.link_to}>
        <AmazeeIOButton classes="px-4 btn-boldish mt-2 py-2 inline-block inverted">
          Read More
        </AmazeeIOButton>
      </Linked>
    </div>
  );
};

const TechSection = ({ result }: { result: TypeProjectPageData }) => {
  const textEl = (
    <div className="item-block-text">
      <h5>{result.title}</h5>
      {RichTextRender(result.tech_section_text)}
    </div>
  );

  const imageEl = (
    <div className="flex flex-wrap">
      {result.logos.map((logo, index) => {
        return (
          <img
            key={index}
            className="w-1/2 md:w-1/4 px-2"
            src={logo.logo.url}
            alt={logo.logo.alt}
          />
        );
      })}
    </div>
  );

  return (
    <div className={classNames("row")}>
      <div className={classNames("col-12")}>
        <div className={classNames("row items-center")}>
          <div className="col-12 col-md-6">{textEl}</div>
          <div className="col-12 col-md-6">{imageEl}</div>
        </div>
      </div>
    </div>
  );
};

const ProjectsPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeProjectPageQueryResult;
}) => {
  const edge = data.allPrismicProjectsPageV2.edges.slice(0, 1).pop();
  if (!edge) return null;
  const result = usePreviewData(
    edge.node.data,
    "prismicProjectsPageV2",
    true
  ) as TypeProjectPageData;

  console.log("location:", location);

  return (
    <AmazeePage location={location}>
      <div id="projects-page">
        <PageTitle title={result.page_meta_title} />
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/solid.min.css"
          />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <AmazeeIoMetadata
          title={result.page_meta_title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <section className="main pt-5 pb-10">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-md-10">
                {result.blocks.map((block, index) => (
                  <ProjectsPageBlock index={index} key={index} block={block} />
                ))}
              </div>
              <div className="col-12 col-md-10 logo-section">
                <TechSection result={result} />
              </div>
            </div>
          </div>
        </section>

        {/* HOST ANYTHING */}
        <section className="light-grey host-anything">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-md-9">
                <h3 className="mb-4 text-center">
                  {RichTextRender(result.section_title)}
                </h3>
                {/*<p className="text-center">{RichTextRender(result.subtitle)}</p>*/}
              </div>
              <div className="col-12 col-md-9">
                <div className="row items-start justify-between">
                  {result.services.map((service, index) => (
                    <ProjectSellingPoint key={index} service={service} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* WebOps */}
        <section className="">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-md-10">
                <h3 className="mb-4 text-center">
                  {RichTextRender(result.title2)}
                </h3>
                <p className="text-center">
                  {RichTextRender(result.subtitle1)}
                </p>
              </div>
              <div className="col-12 col-lg-9">
                <div className="row items-start justify-between">
                  {result.selling_point.map((sellingPoint, index) => (
                    <ProjectServiceSection
                      key={index}
                      sellingPoint={sellingPoint}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Request a demo */}
        <section className="dark-grey extra-padding">
          <div className="container">
            <div className="row justify-center">
              <div className="col-12 col-md-8 text-center mb-4">
                <div className="text-white">
                  {RichTextRender(result.title1)}
                </div>
              </div>
              <div className="col-12 text-center">
                <Linked link_to={result.button_link_to}>
                  <AmazeeIOButton classes="py-2 inverted inline-block px-4">
                    {result.button_text}
                  </AmazeeIOButton>
                </Linked>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticLagoonPage: React.FC<{ location: Location }> = ({ location }) => {
  const query = graphql`
    {
      allPrismicProjectsPageV2 {
        edges {
          node {
            data {
              page_meta_description
              page_meta_title
              page_meta_thumbnail {
                url
              }
              title
              section_title
              button_text1
              button_text
              blocks {
                text {
                  ...PrismicStructuredTextTypeFragment
                }
                image_placement
                image {
                  url
                  alt
                }
              }
              button_link_to {
                document {
                  ... on PrismicPageDefinitions {
                    data {
                      ...PrismicPageDefinitionsDataTypeFragment
                    }
                  }
                }
              }
              contact_us_link_to {
                document {
                  ... on PrismicPageDefinitions {
                    data {
                      ...PrismicPageDefinitionsDataTypeFragment
                    }
                  }
                }
              }
              logos {
                logo {
                  url
                  alt
                }
              }
              selling_point {
                title3
                text {
                  ...PrismicStructuredTextTypeFragment
                }
                image {
                  url
                  alt
                }
              }
              services {
                image {
                  alt
                  url
                }
                link_to {
                  document {
                    ... on PrismicPageDefinitions {
                      data {
                        ...PrismicPageDefinitionsDataTypeFragment
                      }
                    }
                  }
                }
                text {
                  ...PrismicStructuredTextTypeFragment
                }
                title1
              }
              subtitle {
                ...PrismicStructuredTextTypeFragment
              }
              subtitle1 {
                ...PrismicStructuredTextTypeFragment
              }
              tech_section_text {
                ...PrismicStructuredTextTypeFragment
              }
              title1 {
                ...PrismicStructuredTextTypeFragment
              }
              title3 {
                ...PrismicStructuredTextTypeFragment
              }
              subtitle2 {
                ...PrismicStructuredTextTypeFragment
              }
              title2 {
                ...PrismicStructuredTextTypeFragment
              }
            }
          }
        }
      }
    }

    fragment PrismicStructuredTextTypeFragment on PrismicStructuredTextType {
      html
      raw
      text
    }

    fragment PrismicPageDefinitionsDataTypeFragment on PrismicPageDefinitionsDataType {
      url
      is_external
      friendly_name
    }
  `;

  return (
    <StaticQuery
      render={(result) => <ProjectsPage location={location} data={result} />}
      query={query}
    />
  );
};
export default StaticLagoonPage;
